$max: 50;
$offset: 5;
$unit: 'px';

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.h-0 {
  height: 0 !important;
}


.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}
