//@import '~bootstrap/scss/bootstrap-reboot';
$blue:                        #0066cc !default;
$input-btn-font-size:         14px !default;
$btn-border-radius:           4px !default;
$btn-font-weight:             500 !default;
$light:                       #b6b6b6;
$dark:                        #222;

@import '~bootstrap/scss/bootstrap';

@import 'scss/material';
@import 'scss/util';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}




@import "~ng-pick-datetime/assets/style/picker.min.css";


/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap.scss';


html, body {
  height: 100%;
  background: #fafafa
}

body {
  background: #1a1a1a;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-block {
  display: block;
}

.slim-dialog mat-dialog-container {
  padding: 0;
}

.bg-primary {
  background: map-get($mat-primary, main);
}

.bg-accent {
  background: map-get($mat-accent, main);
}

.bg-dark {
  background: #1a1a1a;
}

.text-white {
  color: white;
}

.card-primary {
  @extend .bg-primary;
  mat-card-title {
    color: white;
  }
  mat-card-subtitle {
    color: rgb(197 197 197 / 0.9);
  }
}

.card-accent {
  @extend .bg-accent;
  mat-card-title {
    color: white;
  }
  mat-card-subtitle {
    color: rgb(197 197 197 / 0.9);
  }
}

.card-dark {
  @extend .bg-dark;
  mat-card-title {
    color: white;
  }
  mat-card-subtitle {
    color: rgb(197 197 197 / 0.9);
  }
}

.mat-sidenav-container {
  height: 100%;
  width: 100%;
}
//
//.mat-dialog-container {
//  max-height: 90vh !important;
//}

.mat-simple-snackbar-action span.mat-button-wrapper {
  color: map-get($mat-primary, main) !important;
}

.mat-snack-bar-container, .mat-toolbar.mat-accent {
  background: #1a1a1a;
}

.mat-tab-body-wrapper {
  padding-bottom: 64px;
}

.ngx-file-drop__drop-zone{
  margin: 0 0 !important;
  border-color: #bfbfbf !important;
  border-radius: 8px !important;
  padding: 10px !important;
  box-sizing: content-box;

  .ngx-file-drop__content {
    color: #444 !important;
    height: 100% !important;
    flex-direction: column;
    text-align: center;
  }
}

mat-dialog-content {
  box-shadow: 0px -7px 13px -16px inset black, 0px 7px 13px -16px inset black;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.angular-editor-textarea {
  min-height: 240px !important;
}


.update-image {
  position: absolute !important;
  top: -14px !important;
  right: 0 !important;
}

.mat-btn-sm {
  font-size: 12px !important;
  padding: 0.5em 1em !important;
  line-height: 1.5em !important;
}

button:focus {
  outline: none;
}

mat-dialog-actions {
  position: relative;
  mat-spinner {
    position: absolute !important;
    right: 0;
  }
}

td.mat-cell.cdk-cell,
td.mat-footer-cell.cdk-footer-cell,
th.mat-header-cell.cdk-header-cell {
  padding-left: 10px;
  padding-right: 10px;
}

.mat-form-field-sm {
  font-size: 0.8em;
}

//mat-dialog-contooeeeeeainer.mat-dialog-container {
//  padding: 0 !important;
//  background: none !important;
//  box-shadow: none !important;
//}

.cadence-view-dialog {
  mat-dialog-container {
    padding: 0;
    min-width: 80vw;
    max-height: 90vh;
  }
}


.cad-thumbnail {
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.ql-editor.ql-blank {
  height: 120px;
}
.ql-editor {
  max-height: 480px;
  min-height: 120px;
  margin-bottom: 15px;
  overflow: auto;
}


tr.mat-row.cdk-row.cdk-drag.cdk-drag-preview {
  background: white;
}

.form-submit-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal{
  z-index: 999 !important;
}

.modal-backdrop.show {
  z-index: 998 !important;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  height: 45px !important;
}


.mat-toolbar-row, .mat-toolbar-single-row, .mat-toolbar-multiple-rows {
  height: 48px;
  min-height: 48px;
}

.bg-black {
  background: #000;
  &:hover, &:active, &:focus {
    background: #0A0A0A !important;
  }
}

[aria-label="aac-m-0_p-0"] {
  @extend .m-0;
  @extend .p-0;
}


.mat-tab-body-wrapper {
  padding-top: 20px;
}
